<template>
  <div>
    <div v-if="isNewLegacyQRCode" class="appbackground">
      <v-container fill-height fluid>
        <v-row align="center" justify="center">
          <v-col align="center">
            <h3>This qr code does not exist and uses a depricated qr identification</h3>
            <br>
            <h3>Please use the updated qr identification to create new qr codes</h3>
            <v-btn class="adminbutton mt-8" to="/logout"><v-icon class="pa-2">mdi-logout</v-icon>Log out</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else class="appbackground">
      <v-progress-linear :active="loading" indeterminate color="blue"></v-progress-linear>
      <AppBarMobile :header=header></AppBarMobile>
      <div class="navtext pt-0 ml-5 mb-5">/ {{ isEditing ? `${qrCodeDescriptionDisplayValue} / Edit` : `New QR - ID:
        ${qrstring
          === undefined
          ? qrworkspaceid : qrstring} / Register` }}</div>
      <div class="mobile-view">
        <v-container class="px-12">
          <div class="infotext text-center mb-5">{{ isEditing ? "Edit" : "Register a" }} QR-code by<br />filling out
            the
            form below.</div>
          <v-form v-model="isQrFormValid">
            <v-select required :rules="requiredRules" :items="qrCategories" item-title="description" item-value="id"
              return-object label="Select type" v-model="qrCodeItem.qrCategory" solo></v-select>
            <div v-if="qrCodeItem.qrCategory !== null">
              <v-text-field required :rules="requiredRules" label="* Description" v-model="qrCodeItem.description"
                outlined></v-text-field>
              <div style="display: flex; width: 100%;align-items: center; margin-bottom: 22px;">
                <div style=" width: 100%;">
                  <v-combobox v-model="qrCodeItem.location" :items="locations" item-value="id" item-title="name"
                    hide-details label="Location" outlined></v-combobox>
                </div>
                <div v-if="qrCodeItem.location && qrCodeItem.location.imageId"
                  style="align-items: center; margin: 0 12px">
                  <v-icon size="30px" :class="qrCodeItem.latLng && 'map-marker-selected'" @click="mapDialog = true"
                    style="color: var(--report-red); margin: auto 0; position: relative;">
                    mdi-map-marker-radius
                  </v-icon>
                </div>
              </div>
              <v-text-field label="Location description" v-model="qrCodeItem.position" outlined></v-text-field>
              <v-dialog fullscreen v-model="mapDialog">
                <div class="map-area-mobile">
                  <div v-if="isEditing" class="map-area-mobile">
                    <MapComponent v-if="qrCodeItem.location != null && qrCodeItem.location.imageName && mapDialog"
                      :qrId="-1" :name="qrCodeItem.location.imageName" :mobileEdit="true"
                      :qrs="[{ qrId: qrCodeItem.id, qrString: qrCodeItem.id, qrDescription: qrCodeItem.description, latLng: qrCodeItem.latLng }]"
                      :marker-draggable="true" @updateLocation="updateLocationFromChild" />
                  </div>
                  <div v-else class="map-area-mobile">
                    <MapComponent v-if="qrCodeItem.location != null && qrCodeItem.location.imageName && mapDialog"
                      :qrId="-1" :name="qrCodeItem.location.imageName" :mobileEdit="true"
                      @updateLocation="updateLocationFromChild" />
                  </div>
                  <div class="mobile-map-buttons">
                    <v-btn tile outlined class="mb-3 mr-3 mobile-map-button" @click="mapDialog = false"><v-icon small
                        class="py-2 mr-1">mdi-close</v-icon> Close</v-btn>
                  </div>
                </div>
              </v-dialog>
              <div v-if="useForms" style="margin-bottom: 22px;">
                <div class="switch-table-row">
                  <p style="margin: auto 0; height: fit-content;">{{ getForms[0].description }}</p>
                  <v-switch color="green" @update:model-value="switchContactRequestActive"
                    v-model="getForms[0].isActive" class="switch-toggle" hide-details inset></v-switch>
                </div>
                <div class="switch-table-row">
                  <p style="margin: auto 0; height: fit-content;">{{ getForms[1].description }}</p>
                  <v-switch color="green" @update:model-value="switchFeedbackActive" v-model="getForms[1].isActive"
                    class="switch-toggle" hide-details inset></v-switch>
                </div>
              </div>
              <div v-for="(item, index) in qrCodeItem.qrDetails" :key="index" style="display: flex; width: 100%">
                <div class="newitem pa-3" style="margin-bottom: 22px; width: 100%">
                  <div style="margin-bottom: 22px; display: flex; justify-content: space-between;">
                    <p class="infotext" style="margin: auto 0; max-width: fit-content;">Inventory</p>
                    <v-btn @click="removeItem(index)" size="36px">
                      <v-icon>
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </div>
                  <div style="display: flex;">
                    <v-select required :rules="requiredRules" :items="inventoryItems" item-title="description"
                      item-value="id" label="* Item" v-model="item.inventoryId"></v-select>
                  </div>
                  <div>
                    <v-text-field label="Custom Type Description" v-model="item.aliasTypeDescription"
                      hide-details></v-text-field>
                  </div>
                </div>
              </div>
              <v-btn @click="addItem()" class="addbutton" style="margin-bottom: 22px;" dark color="grey">
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </div>
          </v-form>
        </v-container>
        <v-row justify="center">
          <div v-if="isEditing">
            <v-btn v-if="qrstring === undefined" class="smallbutton mb-7 me-5"
              @click="$router.push({ name: 'MobileSelect', params: { workspaceId: $router.currentRoute.value.params.workspaceId, qrWorkspaceId: $router.currentRoute.value.params.qrWorkspaceId } })"
              :loading="sending" :disabled="sending">{{ isEditing ? 'Back' : 'Reset' }}</v-btn>
            <v-btn v-else class="smallbutton mb-7 me-5"
              @click="$router.push({ name: 'MobileSelectLegacy', params: { qrString: $router.currentRoute.value.params.qrstring } })"
              :loading="sending" :disabled="sending">{{ isEditing ? 'Back' : 'Reset' }}</v-btn>
          </div>
          <v-btn class="smallbutton mb-7" @click="save()" :loading="sending" :disabled="!isQrFormValid">Save</v-btn>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex'
import AppBarMobile from '../components/AppBarMobile.vue'
import MapComponent from '../components/MapComponent.vue'

const contactRequest = 'Contact request'
const feedback = 'Feedback'

export default {
  props: [
    'qrname',
    'qrstring',
    'workspaceid',
    'qrworkspaceid',
    'isEditMode'
  ],
  components: {
    MapComponent,
    AppBarMobile,
  },
  computed: {
    ...mapGetters([
      'username',
      'useForms'
    ]),
    getForms() {
      return [
        { description: contactRequest, isActive: this.qrCodeItem.isContactRequestActive },
        { description: feedback, isActive: this.qrCodeItem.isFeedbackActive },
      ]
    }
  },
  data() {
    return {
      isChangeCategoryConfirmed: false,
      isCategoryReverting: false,
      qrCodeDescriptionDisplayValue: "",
      qrCodeItem: {},
      isNewLegacyQRCode: false,
      workspaceId: 0,
      isEditing: null,
      qrCategories: [],
      newQrCategoryId: null,
      localpicture: {},
      saving: false,
      loading: false,
      sending: false,
      isQrFormValid: false,
      inventoryItems: [],
      show: true,
      header: this.isEditMode ? "Edit QR" : "Register QR",

      formHeaders: [
        { text: 'Description', value: 'description', sortable: false },
        { text: 'Active', value: 'isActive', sortable: false, width: 150 },
      ],
      requiredRules: [
        v => !!v || 'Field is required',
      ],

      newQr: {
        id: 0,
        description: null,
        workspaceId: this.workspaceid,
        qrWorkspaceId: this.qrworkspaceid,
        qrCategoryId: null,
        qrString: this.qrstring,
        latLng: null,
        isContactRequestActive: false,
        isFeedbackActive: false,
      },

      locations: [],

      editQrId: null,
      mapDialog: false,

      isAuthorized: null,
      checkDone: false
    }
  },
  created() {
    this.getQrData()
  },
  watch: {
    "qrCodeItem.qrCategory": function (newValue, oldValue) {
      console.log(newValue)
      console.log(oldValue)
      const isUncategorized = this.qrCodeItem.qrCategory.description === 'Uncategorized'
      if (oldValue === undefined) {
        return
      }
      if (oldValue.id === newValue.id || this.isCategoryReverting) {
        this.isCategoryReverting = false
        return
      }
      if (oldValue && this.qrCodeItem.qrDetails.length > 0 && !isUncategorized) {
        this.$root.$ConfirmDialog.open('Change QR Category', 'Are you sure? All current items will be removed.').then((response) => {
          console.log("CONFIRM IS CALLED SOUND THE ALARMS")
          if (response) {
            this.changeQrCategory(newValue)
            return
          } else {
            this.isCategoryReverting = true
            this.qrCodeItem.qrCategory = oldValue
            return
          }
        })
      } else {
        this.changeQrCategory(newValue)
        return
      }
      return
    }
  },
  methods: {
    addItem() {
      this.qrCodeItem.qrDetails.push({ id: -1 });
    },
    removeItem(index) {
      this.qrCodeItem.qrDetails.splice(index, 1)
    },
    getInventoryByType(qrCategoryId) {
      const isUncategorized = this.qrCodeItem.qrCategory.description === 'Uncategorized'
      HTTP.get(`/qr/getinventory/${qrCategoryId}/${isUncategorized}/${this.workspaceId}`).then((response) => {
        this.inventoryItems = response.data
      }).catch(() => { })
    },
    changeQrCategory(newQrCategory) {
      if (newQrCategory !== 'Uncategorized') this.qrCodeItem.qrDetails = []
      console.log(newQrCategory)
      this.getInventoryByType(newQrCategory.id)
    },
    save() {
      if (typeof this.qrCodeItem.location == "string") {
        const newLocation = { name: this.qrCodeItem.location, workspaceId: this.workspaceId, usePinCode: false }
        this.qrCodeItem.location = newLocation
      }
      HTTP.post('/qr/save/mobile_edit',
        this.qrCodeItem).then(() => {
          if (this.isEditing) {
            this.$router.push({ name: 'MobileEditSuccess', params: { editType: "e" } });
          } else {
            this.$router.push({ name: 'MobileEditSuccess', params: { editType: "r" } });
          }
        }).catch(() => {
          this.$store.dispatch('showError', 'Your changes could not be saved.')
        })
    },
    switchContactRequestActive(item) {
      this.qrCodeItem.isContactRequestActive = item;
    },
    switchFeedbackActive(item) {
      this.qrCodeItem.isFeedbackActive = item;
    },
    async getQrData() {
      let getSingleQRCodeEndpointVariable;

      if (this.qrstring !== undefined) {
        getSingleQRCodeEndpointVariable = this.qrstring
      } else {
        getSingleQRCodeEndpointVariable = `${this.workspaceid}/${this.qrworkspaceid}`
      }
      await HTTP.get('/qr/get/s/' + getSingleQRCodeEndpointVariable).then(response => {
        this.qrCodeItem = response.data
        this.qrCodeDescriptionDisplayValue = response.data.description
        this.isEditing = true
        this.workspaceId = response.data.workspaceId
        this.getComplementoryData()
      }).catch(async (error) => {
        console.log(error)
        if (error.response.status === 401) {
          await this.$store.dispatch('showError', 'You are not authorized to edit this qr code.')
          await this.$router.push({ name: "Logout" })
        } else if (error.response.status === 400) {
          await this.$store.dispatch('showError', 'Invalid qr identification format')
        } else if (error.response.status === 404) {
          this.getComplementoryData()
          this.isEditing = false
        }
      })
    },
    async getComplementoryData() {
      await HTTP.get(`/qr/getinventory/${this.qrCodeItem.qrCategory.id}/${this.qrCodeItem.qrCategory.description === 'Uncategorized'}/${this.qrCodeItem.workspaceId}`).then(response => {
        this.inventoryItems = response.data
      }).catch(() => { })
      await HTTP.get(`/location/get/${this.qrCodeItem.workspaceId}`).then(response => {
        this.locations = response.data
      }).catch(() => { })
      await HTTP.get(`/mobil/organization/form/c/workspace/${this.qrCodeItem.workspaceId}`).then((response) => {
        this.$store.dispatch('doesOrganizationUseForms', response.data)
      }).catch(() => {
        this.$store.dispatch('doesOrganizationUseForms', false)
      })
      await HTTP.get('/qr/qr_category/get/a/workspace/' + this.workspaceId).then((response) => {
        this.qrCategories = response.data
      }).catch(() => { })
    },
    updateLocationFromChild(value) {
      this.qrCodeItem.latLng = value
    },
    clearCoordinates() {
      this.qrCodeItem.latLng = null
    },
  }
}
</script>

<style scoped>
.remove-btn {
  display: block !important;
  margin-left: auto !important;
  margin-right: 0 !important;
  border-radius: 50%;
  cursor: pointer;
}

.switch-table-row {
  display: flex;
  justify-content: space-between;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #3D3D3D;
}

.switch-toggle {
  margin: 0 !important;
  padding: 0 !important;
}

.mobile-view {
  max-width: 600px;
  margin: auto auto;
}

.mobile-map-buttons {
  position: absolute;
  z-index: 999;
  right: 0;
  bottom: 0;
}

.mobile-map-button {
  background-color: #fff;
  border: 2px solid #ccc;
  width: 90px;
}
</style>
