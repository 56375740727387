<template>
  <v-card style="width: 75vw; margin: 32px auto">
    <div class="d-flex align-start" style="padding: 24px;">
      <h2 class="mr-auto">Locations</h2>
      <Tooltip :message="tooltipMessage"></Tooltip>
    </div>
    <v-row class="d-flex align-center mx-5 mb-3">
      <v-col cols=8>
        <v-text-field v-model="search" label="Search" hide-details clearable></v-text-field>
      </v-col>
      <v-col cols=4>
        <v-btn class="me-3" color="white" @click="newitem()">
          <v-icon medium class="py-2 mr-2">mdi-plus</v-icon> New
        </v-btn>
        <v-btn color="white" @click="editImages()">
          <v-icon small class="py-2 mr-2">mdi-pencil</v-icon> Edit images
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table dense :search="search" :headers="headers" :items="tableItems" :sort-by.sync="sortOptions.sortBy"
      :sort-desc.sync="sortOptions.sortDesc" :options.sync="sortHandler" :items-per-page="20"
      :footer-props="footerProps" class="elevation-1">
      <template v-slot:[`item.registeredOnQrs`]="{ item }">
        {{ item.registeredOnQrs > 0 ? item.registeredOnQrs : '' }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div style="display: grid; grid-template-columns: 50% 50%">
          <v-icon @click="editItem(item)" color="grey-darken-1" size="30px" class="pa-2">mdi-pencil</v-icon>
          <v-icon @click="deleteItem(item)" color="grey-darken-1" size="30px" class="pa-2">mdi-delete</v-icon>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="mapDialog" width="80vw" eager>
      <v-card>
        <v-card-title>
          Edit - Location
        </v-card-title>
        <v-card-text>
          <div style="display: grid; grid-template-columns: 25% 75%">
            <v-form v-model="isFormValid" ref="form">
              <v-text-field class="mt-0" ref="name" required :rules="reqRules" v-model="editedItem.name"
                label="Name"></v-text-field>
              <v-combobox class="mt-0" item-value="id" item-title="imageOriginalName" v-model="imageItemSelected"
                :items="imageItems" label="Image"></v-combobox>
              <div class="d-flex">
                <v-checkbox v-model="editedItem.usePinCode" style="margin-top: 0px; padding-top: 0px;" hide-details
                  @click="usePinCodeToggler()" label="Use Pincode?"></v-checkbox>
              </div>
              <div v-if="editedItem.usePinCode">
                <h3 v-if="this.editedItem.pinCode.hasPin === false" style="font-weight: normal;">Enter pincode</h3>
                <h3 v-if="this.editedItem.pinCode.hasPin === true" style="font-weight: normal;">Enter new pincode</h3>
                <div class="d-flex w-100">
                  <v-otp-input v-model="pinCode" class="pin-input-edit-location" ref="pininput"
                    :error="checkValidPinCodeInput()" @keydown="checkDigit" input
                    :type="showPinCode === true ? 'number' : 'password'" length="4" maxlength="4" inputmode="numeric"
                    style="max-width: 300px;">
                  </v-otp-input>
                  <div class=" d-flex mx-6 mb-2">
                    <v-icon v-if="!showPinCode" class="ma-auto pa-1" @click="showPinCode = true">mdi-eye-off</v-icon>
                    <v-icon v-else class="ma-auto pa-1" @click="showPinCode = false">mdi-eye-outline</v-icon>
                  </div>
                </div>
                <div class="d-flex" v-if="checkValidPinCodeInput()">
                  <svg style="max-width: 25px; " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <title>alert-circle</title>
                    <path style="fill: #ff5252;"
                      d="M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                  </svg>
                  <p style="color: #ff5252; margin-top: auto; margin-bottom: auto; margin-left: 4px;">
                    Pincode is required!</p>
                </div>
                <v-text-field style="margin-right: 4px" label="Hint" v-model="editedItem.pinCode.hint"></v-text-field>
              </div>
            </v-form>
            <div class="grid-mapView">
              <div v-if="imageItemSelected" class="map-area-general"
                style="padding-right:12px!important;padding-left:0px!important">
                <MapComponent v-if="mapDialog" :qrId="-2" :name="imageItemSelected.imageName" :qrs="registeredQrs.qrs"
                  :marker-draggable="true" :marker-removeable="true" :clearAllMarkers="clearAllMarkers"
                  :showToolTipOnExternalHover="showToolTipOnExternalHover" @updateLocation="updateLocationFromChild" />
              </div>
              <div v-else class="map-area-general" style="padding-right:12px!important;padding-left:0px!important">
                <v-card class="no-image">
                  <v-icon size="70">mdi-image-off-outline</v-icon>
                  No image selected
                </v-card>
              </div>
              <v-card>
                <v-card-title class="mb-0">
                  <h3>QR-codes:</h3>
                </v-card-title>
                <v-card-text>
                  <v-list flat dense>
                    <v-list-item v-for="(qr, index) in registeredQrs.qrs" :key="index" class="qr-list-item">
                      <div style="padding:0;" class="d-flex text-subtitle-1" @click="addQrBackToMap(qr)"
                        @mouseover="showMarkerToolTip(qr)" @mouseout="showMarkerToolTip('')">
                        <div :style="!!qr.latLng ? 'cursor:default;' : 'cursor:pointer'">
                          <v-icon v-if="!qr.latLng" class="add-marker-icon">
                            mdi-map-marker-left</v-icon>
                          <span :class="(!!qr.latLng ? 'qr-has-pos' : 'qr-no-pos')">{{ qr.qrString }} - {{
                            qr.qrDescription
                            }}</span>
                        </div>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile outlined class="mb-3" @click="clearAllQrCoords"><v-icon small class="py-2 mr-1">mdi-close</v-icon>
            Clear</v-btn>
          <v-btn tile outlined class="mb-3" @click="mapDialog = false"><v-icon small
              class="py-2 mr-1">mdi-close</v-icon>
            Cancel</v-btn>
          <v-btn tile outlined class="mb-3 mr-3" :disabled="!checkEditFormValidInputs()" @click="save()"><v-icon small
              class="py-2 mr-1">mdi-content-save</v-icon> Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="triggerNewDialog" width="50vw" eager>
      <v-card>
        <v-card-title>
          New Location
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="isFormValid" ref="form">
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field class="mt-0" ref="name" required :rules="reqRules" v-model="editedItem.name"
                    label="Name"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-btn tile outlined :disabled="selectedImage.length >= 1" @click="triggerSelectImage"
                    style="margin-right: 12px">Select
                    image</v-btn>
                  <v-btn tile outlined :disabled="selectedImage.length >= 1"
                    @click="newImageFromEditDialog = true">Upload
                    image</v-btn>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-data-table style="max-height: 500px; overflow-y: auto;" class="elevation-1"
                    :headers="imagePreviewHeaders" :items="selectedImage" hide-default-footer dense hide-default-header>
                    <template v-slot:[`item.action`]="{ item }">
                      <v-icon color="grey-darken-1" size="30px" class="pa-2"
                        @click="removeSelectedImageChoice(item)">mdi-delete</v-icon>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col cols="12" class="d-flex py-0">
                  <v-checkbox v-model="editedItem.usePinCode" @click="usePinCodeToggler()" hide-details></v-checkbox>
                  <p class="ma-auto ml-0">Use Pincode?</p>
                </v-col>
                <v-col v-if="editedItem.usePinCode" cols="12" class="py-0">
                  <h3 style="font-weight: normal;">Input pincode</h3>
                  <div class="d-flex">
                    <v-otp-input v-model="pinCode" ref="pininput" @keydown="checkDigit" input
                      :type="showPinCode === true ? 'number' : 'password'" length="4" maxlength="4" inputmode="numeric"
                      style="max-width: 30%; max-height:fit-content!important">
                    </v-otp-input>
                    <div class="d-flex mx-6 mb-2">
                      <v-icon v-if="!showPinCode" class="ma-auto pa-1"
                        @click="showPinCode = true">mdi-eye-outline</v-icon>
                      <v-icon v-else class="ma-auto pa-1" @click="showPinCode = false">mdi-eye-off</v-icon>
                    </div>
                  </div>
                  <v-text-field label="Hint" v-model="editedItem.pinCode.hint"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile outlined class="mb-3" @click="resetImageValues()"><v-icon small
              class="py-2 mr-1">mdi-close</v-icon>
            Cancel</v-btn>
          <v-btn tile outlined class="mb-3 mr-3" :disabled="!checkFormValidInputs()" @click="saveNewLocation()"><v-icon
              small class="py-2 mr-1">mdi-content-save</v-icon> Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="triggerSelectImageDialog" width="50vw" eager>
      <v-card>
        <v-card-title>
          Select image
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="isFormValid" ref="form">
              <v-row>
                <v-col cols="12"> <v-combobox class="mt-0" item-value="id" item-title="imageOriginalName"
                    v-model="imageItemSelected" :items="imageItems" label="Image"></v-combobox>
                </v-col>
                <v-col cols="12" class="py-0" style=" overflow-y: hidden">
                  <div>
                    <div v-if="imageItemSelected" style="padding-left:0px!important; height: 60vh;">
                      <MapComponent v-if="triggerSelectImageDialog" :name="imageItemSelected.imageName"
                        :clearAllMarkers="clearAllMarkers" @updateLocation="updateLocationFromChild" />
                    </div>
                    <div v-else style="padding-left:0px!important; height: 60vh;">
                      <v-card class="no-image">
                        <v-icon size="70">mdi-image-off-outline</v-icon>
                        No image selected
                      </v-card>
                    </div>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn tile outlined class="mb-3" @click="triggerSelectImageDialog = false"><v-icon small
                          class="py-2 mr-1">mdi-close</v-icon>
                        Cancel</v-btn>
                      <v-btn tile outlined class="mb-3 mr-3" :disabled="imageItemSelected === null"
                        @click="addImageToSelectedImage(imageItemSelected)"><v-icon small
                          class="py-2 mr-1">mdi-content-save</v-icon>
                        Select</v-btn>
                    </v-card-actions>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="imageDialog" max-width="800px" eager>
      <v-card>
        <v-card-title>
          <div class="image-dialog">
            Edit images
            <v-btn tile outlined @click="newImage()"><v-icon medium class="py-2 mr-2">mdi-upload</v-icon>Upload</v-btn>
          </div>
        </v-card-title>
        <v-container>
          <v-data-table dense :headers="imageHeaders" :items="imageItems" hide-default-footer disable-pagination
            item-key="key" class="elevation-1">
            <template v-slot:[`item.dateAdded`]="{ item }">
              {{ item.dateAdded.split('T')[0] }} {{ item.dateAdded.split('T')[1].substring(0, 5) }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <div style="display: grid; grid-template-columns: 50% 50%;">
                <v-icon @click="editImageItem(item)" color="grey-darken-1" size="30px" class="pa-2">mdi-pencil</v-icon>
                <v-icon @click="deleteImageItem(item)" color="grey-darken-1" size="30px"
                  class="pa-2">mdi-delete</v-icon>
              </div>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile outlined class="mr-3" @click="imageDialog = false"><v-icon small
                class="py-2 mr-1">mdi-close</v-icon> Close</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
      <v-dialog v-model="editImageDialog" max-width="600px" eager>
        <v-card>
          <v-card-title>
            <span class="headline">Update image</span>
          </v-card-title>
          <v-card-text style="padding-bottom:0;">
            <v-container style="padding-bottom:0;">
              <v-form v-model="isEditImageFormValid" ref="editImageform">
                <v-row>
                  <v-col cols="6" style="padding-bottom:0;">
                    <v-text-field disabled v-model="editedImage.currentImageName" label="Current image"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-btn tile outlined class="mt-3" @click="uploadReplacementImage"><v-icon medium
                        class="py-2 mr-1">mdi-upload</v-icon> Upload new image</v-btn>
                    <input id="single-file-input" type="file" name="photos" accept="image/*"
                      @change="singleFileChange($event)" style="display:none;">
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" style="padding-bottom:0; padding-top:0;">
                    <v-text-field v-if="editedImage.replacementImageName" disabled
                      v-model="editedImage.replacementImageName" label="New image"></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile outlined class="mb-3" @click="editImageDialog = false"><v-icon small
                class="py-2 mr-1">mdi-close</v-icon> Cancel</v-btn>
            <v-btn tile outlined class="mb-3 mr-3" @click="saveEditedImage"
              :disabled="!editedImage.replacementImage"><v-icon small class="py-2 mr-1">mdi-content-save</v-icon>
              Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="newImageDialog" max-width="600px" eager>
        <v-card>
          <v-card-title>
            Select image(s)
          </v-card-title>
          <v-container>
            <form enctype="multipart/form-data" novalidate>
              <div class="dropbox">
                <input id="file-input" type="file" multiple name="photos" accept="image/*" class="input-file"
                  @change="filesChange($event); fileCount = $event.target.files.length">
                <p v-if="!isUploadingFiles" class="upload-text">Drag your file(s) here</p>
                <p v-if="!isUploadingFiles" class="upload-text">or click to browse.</p>
                <p v-if="!isUploadingFiles" class="upload-text file-count">Files selected: {{ fileCount }}</p>
                <p v-if="isUploadingFiles" class="upload-text">Uploading images...</p>
              </div>
            </form>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile outlined class="mb-3" @click="newImageDialog = false; clearFileInput()"><v-icon small
                class="py-2 mr-1">mdi-close</v-icon> Cancel</v-btn>
            <v-btn tile outlined class="mb-3 mr-3" :disabled="!isImageFormValid" @click="saveImage()"><v-icon small
                class="py-2 mr-1">mdi-content-save</v-icon> Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
    <v-dialog v-model="newImageFromEditDialog" max-width="600px" eager>
      <v-card>
        <v-card-title>
          Select image(s)
        </v-card-title>
        <v-container>
          <form enctype="multipart/form-data" novalidate>
            <div class="dropbox">
              <input id="file-input-new-location" type="file" multiple name="photos" accept="image/*" class="input-file"
                @change="filesChangeFromEditView($event); fileCount = $event.target.files.length">
              <p v-if="!isUploadingFiles" class="upload-text">Drag your file here</p>
              <p v-if="!isUploadingFiles" class="upload-text">or click to browse.</p>
              <p v-if="!isUploadingFiles && this.uploadedImage == null" class="upload-text file-count">
                File selected:
              </p>
              <p v-if="!isUploadingFiles && this.uploadedImage != null" class="upload-text file-count">File
                selected: {{
                  this.uploadedImage.filename
                }}</p>
              <p v-if="isUploadingFiles" class="upload-text">Uploading image...</p>
            </div>
          </form>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile outlined class="mb-3" @click="newImageFromEditDialog = false; clearFileInput()"><v-icon small
              class="py-2 mr-1">mdi-close</v-icon> Cancel</v-btn>
          <v-btn tile outlined class="mb-3 mr-3" @click="newImageFromEditDialog = false; saveImageFromEditView()"
            :disabled="!isImageFormValid"><v-icon small class="py-2 mr-1">mdi-content-save</v-icon> Continue</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex'
import MapComponent from '../../components/MapComponent.vue'
// import { EventBus } from '@/store/event-bus.js'
import Tooltip from '../../components/HelpTooltip.vue'

export default {
  components: {
    MapComponent,
    Tooltip,
  },
  data() {
    return {
      imageDialog: false,
      newImageDialog: false,
      editImageDialog: false,
      mapDialog: false,
      triggerNewDialog: false,
      enableEscClear: true,
      triggerSelectImageDialog: false,
      showPinCode: false,
      newImageFromEditDialog: false,
      uploadedImage: null,
      pinCode: "",

      search: '',
      footerProps: { 'items-per-page-options': [20, 50, 100] },
      headers: [
        { title: 'Name', key: 'name', width: '30%' },
        { title: 'Image', key: 'imageOriginalName', },
        { title: 'Registered on QR', key: 'registeredOnQrs' },
        { title: 'Edit', key: 'action', sortable: false, width: 120 },

      ],
      imagePreviewHeaders: [
        { title: 'Name', key: 'imageOriginalName' },
        { title: 'Edit', key: 'action', sortable: false, width: 120 }
      ],
      imageHeaders: [
        { title: 'Added', key: 'dateAdded', width: 160 },
        { title: 'Name', key: 'imageOriginalName' },
        { title: 'Edit', key: 'action', sortable: false, width: 120 },
      ],

      tableItems: [],
      imageItems: [],
      selectedImage: [],

      editedItem: {},
      imageItemSelected: null,

      editedImage: {
        currentImageId: null,
        currentImageName: null,
        replacementImage: null,
        replacementImageName: null,
      },

      localPictures: [],

      reqRules: [
        v => !!v || 'Field is required',
      ],
      isFormValid: false,
      isImageFormValid: false,
      isEditImageFormValid: false,
      isUploadingFiles: false,

      fileCount: 0,

      sortHandler: {},
      sortOptions: {
        sortBy: [],
        sortDesc: [],
      },

      registeredQrs: {
        locationId: null,
        locationName: null,
        imageId: null,
        imageName: null,
        qrs: []
      },
      displayMap: false,
      clearAllMarkers: false,
      showToolTipOnExternalHover: '',

      tooltipMessage: [
        "Location",
        'Locations makes it possible to connect a map (image) to your qr-code, on the map you place a marker for exact positioning. To add a new location you need to upload an image first under “Edit images”. After you have done that you can choose “New”, name the location and select your image.'
      ],
    }
  },
  computed: {
    ...mapGetters([
      'sort',
      'workspaceid'
    ]),
  },
  watch: {
    sortHandler: {
      deep: true,
      handler(obj) {
        const objSort = { sortBy: obj.sortBy, sortDesc: obj.sortDesc }

        if (JSON.stringify(objSort) !== JSON.stringify(this.sortOptions)) {
          const newObj = this.sort
          newObj.locationSort = objSort
          this.$store.dispatch('updateSort', newObj)
        }
      }
    },
    workspaceid(newValue) {
      this.getitems(newValue)
    },
    imageDialog(val) {
      if (val) this.enableEscClear = false
      if (!val) {
        setTimeout(() => {
          this.enableEscClear = true
        }, 100)
      }
    },
    triggerSelectImageDialog(val) {
      if (val) {
        this.enableEscClear = false
      } else {
        setTimeout(() => {
          this.enableEscClear = true
        }, 100)
      }
    },
    mapDialog(val) {
      if (val) {
        this.enableEscClear = false
      } else {
        setTimeout(() => {
          this.enableEscClear = true
        }, 100)
      }
    },
    imageItemSelected(val) {
      if (!val) {
        this.registeredQrs.imageName = null
      }
      else {
        this.registeredQrs.imageName = val.imageName
      }
    },
  },
  created() {
    this.getitems(this.workspaceid)
    this.sortOptions = this.sort.locationSort || this.sortOptions
  },
  mounted() {
    document.addEventListener('keydown', this.clearSearchEsc)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.clearSearchEsc)
  },
  methods: {
    filesChangeFromEditView(e) {
      if (!e.target.files.length) {
        this.isImageFormValid = false
        return;
      }
      this.isImageFormValid = true
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const logoImageToUpload = {
          filedata: reader.result.replace('data:', '').replace(/^.+,/, ''),
          filename: file.name,
          filesize: file.size,
          filetype: file.type,
          workspaceid: this.workspaceid
        }
        this.uploadedImage = logoImageToUpload
      }
      reader.readAsDataURL(file);
    },
    saveImageFromEditView() {
      this.isUploadingFiles = true
      if (this.uploadedImage.filedata != undefined) {
        HTTP.post('/image/save', [this.uploadedImage]).then(response => {
          this.isUploadingFiles = false
          this.newImageFromEditDialog = false
          this.selectedImage = response.data
          this.imageItemSelected = response.data[0]
          this.clearFileInput()

          response.data.forEach(img => {
            this.imageItems.push({ id: img.id, imageOriginalName: img.imageOriginalName, dateAdded: img.dateAdded })
          })
        }).catch((e) => {
          this.isUploadingFiles = false
          this.clearFileInput()
          this.$store.dispatch('showError', 'Error when saving: ' + e.response.data)
        })
      }
    },
    checkDigit(event) {
      if (event.key.length === 1 && isNaN(Number(event.key))) {
        event.preventDefault();
      }
    },
    resetPinCodeValues() {
      this.pinCode = ""

      if (this.editedItem.pinCode === undefined) {
        this.editedItem.pinCode = {}
      }
      this.editedItem.pinCode.hint = ""
    },
    usePinCodeToggler() {
      this.resetPinCodeValues()
      if (this.editedItem.usePinCode === true) {
        this.$nextTick(() => {
          this.$refs.pininput.focus()
        }
        )
      }
    },
    resetImageValues() {
      this.selectedImage = []
      this.imageItemSelected = null
      this.triggerNewDialog = false
    },
    saveNewLocation() {
      this.editedItem.pinCode.pin = this.pinCode
      this.saveLocationData()
      this.saveLatLngData()
      this.resetImageValues()
      this.resetPinCodeValues()
    },
    checkFormValidInputs() {
      if ((this.editedItem.name === null || this.editedItem.name === "")) {
        return false
      }
      if (this.editedItem.usePinCode === true) {
        if (this.pinCode === null) {
          return false
        }
        if (this.pinCode.split("").length !== 4) {
          return false
        }
        return true
      }
      return true
    },
    checkEditFormValidInputs() {
      if ((this.editedItem.name === null || this.editedItem.name === "")) {
        return false
      }
      if (this.editedItem.usePinCode === true) {
        if (this.pinCode === null) {
          return false
        }
        if (this.pinCode.split("").length !== 4 && this.pinCode.split("").length !== 0) {
          return false
        }
        if (this.pinCode.split("").length === 0 && this.editedItem.pinCode.hasPin === false) {
          return false
        }
        return true
      }
      return true
    },
    checkValidPinCodeInput() {
      if (this.pinCode === null) {
        return true
      }
      if (this.pinCode.split("").length !== 4 && this.pinCode.split("").length !== 0) {
        return true
      }
      if (this.pinCode.split("").length === 0 && this.editedItem.pinCode.hasPin === false) {
        return true
      }
      return false
    },
    addImageToSelectedImage(item) {
      if (item != null) {
        this.selectedImage.push(item)
      }
      this.triggerSelectImageDialog = false
    },
    triggerSelectImage() {
      this.selectedImage = []
      this.imageItemSelected = null
      this.displayMap = true
      this.triggerSelectImageDialog = true
    },
    getitems(workspaceid) {
      if (this.workspaceid === -1) {
        return
      }
      HTTP.get('/location/get/' + workspaceid).then(response => {
        this.tableItems = response.data
      }).catch(() => {
        this.tableItems = []
      })
    },
    updateLocationFromChild(latLng, qrId) {
      const index = this.registeredQrs.qrs.findIndex(x => x.qrId === qrId)
      this.registeredQrs.qrs[index].latLng = latLng
    },
    addQrBackToMap(qr) {
      if (qr.latLng !== null) return
      if (!this.registeredQrs.imageName) return

      const qrId = qr.qrId
      const index = this.registeredQrs.qrs.findIndex(x => x.qrId === qrId)
      this.registeredQrs.qrs[index].latLng = '0,0'
    },
    clearAllQrCoords() {
      this.clearAllMarkers = !this.clearAllMarkers
      this.registeredQrs.qrs.forEach(qr => {
        qr.latLng = null
      })
    },
    showMarkerToolTip(qr) {
      if (qr.latLng === null) return
      if (qr === '' && this.showToolTipOnExternalHover === '' || qr === '' && this.showToolTipOnExternalHover === undefined) return
      this.showToolTipOnExternalHover = qr.qrString
    },
    save() {
      this.editedItem.pinCode.pin = this.pinCode
      this.saveLocationData()
      this.saveLatLngData()
      this.resetImageValues()
      this.resetPinCodeValues()
      this.mapDialog = false
    },
    saveLocationData() {
      if (this.imageItemSelected) {
        this.editedItem.imageId = this.imageItemSelected.id;
        this.editedItem.imageOriginalName = this.imageItemSelected.imageOriginalName;
        this.editedItem.imageName = this.imageItemSelected.imageName;
      } else {
        this.editedItem.imageId = null;
        this.editedItem.imageOriginalName = null;
        this.editedItem.imageName = null;
      }
      HTTP.post('/location/save/' + this.workspaceid, this.editedItem).then(response => {
        if (this.editedItem.id > 0) {
          var editedIndex = this.tableItems.findIndex(x => x.id === this.editedItem.id);
          var editedLocation = this.tableItems[editedIndex];

          editedLocation.name = this.editedItem.name;
          editedLocation.id = this.editedItem.id;
          editedLocation.imageId = this.editedItem.imageId;
          editedLocation.imageOriginalName = this.editedItem.imageOriginalName;
          editedLocation.imageName = this.editedItem.imageName;
        }
        else {
          this.editedItem.id = response.data;
          this.tableItems.push(this.editedItem);
        }
        this.getitems(this.workspaceid)
      }).catch(e => {
        this.$store.dispatch('showError', 'Error when saving: ' + e.response.data);
      })
    },
    saveLatLngData() {
      if (this.registeredQrs.qrs.length > 0) {
        HTTP.post('/qr/saveqrlatlng', this.registeredQrs.qrs).then(() => { }).catch(e => {
          this.$store.dispatch('showError', 'Error when saving: ' + e.response.data)
        })
      }
    },
    newitem() {
      this.resetImageValues()
      if (this.workspaceid == -1) {
        this.$store.dispatch('showError', 'No workspace selected.<br/><br/>Please make sure you have created at least one workspace and that it is selected.')
        return
      }
      this.editedItem = {
        id: 0,
        name: '',
        imageId: null,
        imageOriginalName: null,
        registeredOnQrs: 0,
        usePinCode: false,
        pinCode: {
          pin: "",
          hint: ""
        }
      }
      this.getImages()
      this.triggerNewDialog = true;
    },
    newImage() {
      this.newImageDialog = true
    },
    removeSelectedImageChoice(item) {
      this.selectedImage = this.selectedImage.filter(si => si.imageId != item.imageId)
      this.imageItemSelected = null
    },
    getImages() {
      if (this.workspaceid === -1) {
        return
      }
      HTTP.get('/image/get/' + this.workspaceid).then(response => {
        this.imageItems = response.data
      })
    },
    editItem(item) {
      this.pinCode = ""
      HTTP.get('/qr/getqrsbylocation/' + item.id).then(response => {
        this.registeredQrs.qrs = response.data
        this.registeredQrs.locationId = item.id
        this.registeredQrs.locationName = item.name
        this.registeredQrs.imageId = item.imageId
        this.registeredQrs.imageName = item.imageName
        this.mapDialog = true

      })
      if (this.workspaceid === -1) {
        return
      }
      HTTP.get(`/location/get/${this.workspaceid}/${item.id}`).then((response) => {
        this.editedItem = response.data
        this.pinCode = response.data.pinCode.pin
      }).catch(e => {
        this.$store.dispatch("showError", e.response.data)
      })

      if (this.$refs.form) this.$refs.form.resetValidation()
      HTTP.get('/image/get/' + this.workspaceid).then(response => {
        this.imageItems = response.data
        this.imageItemSelected = this.imageItems.find(x => x.id === item.imageId) || null
      })
      setTimeout(() => {
        this.$refs.name.focus()
      }, 200)
    },
    editImageItem(item) {
      this.editImageDialog = true

      this.editedImage.currentImageName = item.imageOriginalName
      this.editedImage.currentImageId = item.id
    },
    uploadReplacementImage() {
      document.getElementById('single-file-input').click();
    },
    deleteItem(item) {
      const index = this.tableItems.indexOf(item)

      this.tableItems
      this.$root.$ConfirmDialog.open('Delete item', '<strong>Are you sure?</strong>', { color: 'red' }).then((response) => {
        if (response) {
          HTTP.post('/location/delete', { id: item.id }).then(() => {
            this.tableItems.splice(index, 1)
          }).catch(e => {
            this.$store.dispatch('showError', e.response.data)
          })
        }
      })
    },
    deleteImageItem(item) {
      const index = this.imageItems.indexOf(item)

      this.$root.$ConfirmDialog.open('Delete item', '<strong>Are you sure?</strong>', { color: 'red' }).then((response) => {
        if (response) {
          HTTP.post('/image/delete', { id: item.id }).then(() => {
            this.imageItems.splice(index, 1)
          }).catch(e => {
            this.$store.dispatch('showError', e.response.data)
          })
        }
      })
    },
    editImages() {
      if (this.workspaceid == -1) {
        this.$store.dispatch('showError', 'No workspace selected.<br/><br/>Please make sure you have created at least one workspace and that it is selected.')
        return
      }
      this.imageDialog = true
      HTTP.get('/image/get/' + this.workspaceid).then(response => {
        this.imageItems = response.data
      })
    },
    saveImage() {
      this.isUploadingFiles = true

      HTTP.post('/image/save', this.localPictures).then(response => {
        this.isUploadingFiles = false
        this.newImageDialog = false
        this.clearFileInput()

        response.data.forEach(img => {
          this.imageItems.push({ id: img.id, imageOriginalName: img.imageOriginalName, dateAdded: img.dateAdded })
        })
      }).catch((e) => {
        this.isUploadingFiles = false
        this.clearFileInput()
        this.$store.dispatch('showError', 'Error when saving: ' + e.response.data)
      })
    },
    saveEditedImage() {
      HTTP.post('/image/updateimage', this.editedImage).then(response => {
        this.editImageDialog = false
        this.clearSingleFileInput()

        const index = this.imageItems.findIndex(x => x.id === response.data.id)
        this.imageItems[index].imageOriginalName = response.data.imageOriginalName

        this.tableItems.forEach(item => {
          if (item.imageId === response.data.id) {
            item.imageOriginalName = response.data.imageOriginalName
          }
        })
      }).catch((e) => {
        this.clearSingleFileInput()
        this.$store.dispatch('showError', 'Error when saving: ' + e.response.data)
      })
    },
    filesChange(e) {
      if (!e.target.files.length) {
        this.isImageFormValid = false
        return;
      }
      this.isImageFormValid = true
      const files = e.target.files;
      let file;
      for (let index = 0; index < files.length; index++) {
        file = files[index]
        const reader = new FileReader();
        reader.onloadend = () => {
          const newPic = {
            filedata: reader.result.replace('data:', '').replace(/^.+,/, ''),
            filename: file.name,
            filesize: file.size,
            filetype: file.type,
            workspaceid: this.workspaceid
          }
          this.localPictures.push(newPic)
        }
        reader.readAsDataURL(file);
      }
    },
    singleFileChange(e) {
      if (!e.target.files.length) {
        return;
      }

      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const newPic = {
          filedata: reader.result.replace('data:', '').replace(/^.+,/, ''),
          filename: file.name,
          filesize: file.size,
          filetype: file.type,
        }
        this.editedImage.replacementImage = newPic
        this.editedImage.replacementImageName = newPic.filename

        const imageItem = {
          imageId: Date.now(),
          fileName: newPic.filename,
          fileData: reader.result,
          dateAdded: new Date().toDateString()
        }
        this.addImageToSelectedImage(imageItem)
      }
      reader.readAsDataURL(file);
    },
    clearFileInput() {
      document.getElementById('file-input').value = null
      document.getElementById('file-input-new-location').value = null
      this.fileCount = 0
      this.localPictures = []
      this.isImageFormValid = false
      this.uploadedImage = null
    },
    clearSingleFileInput() {
      this.editedImage = {
        currentImageId: null,
        currentImageName: null,
        replacementImage: null,
        replacementImageName: null,
      }

      document.getElementById('single-file-input').value = null
    },
    clearSearchEsc(e) {
      if (e.keyCode === 27) {
        if (this.mapDialog) this.mapDialog = false
        if (this.enableEscClear) this.search = ''
      }
    },
  }
}
</script>
<style scoped>
.image-dialog {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.dropbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;

  background-color: #EBF5FB;
  width: 100%;
  height: 100px;
  border-radius: 15px;
  border: 2px solid lightgrey;
  position: relative;
}

.input-file {
  width: 100%;
  height: 100px;
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.upload-text {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 0;
  padding: 0;
}

.file-count {
  font-weight: bold;
  font-style: italic;
}

::file-selector-button {
  display: none;
}

.grid-mapView {
  display: grid;
  grid-template-columns: auto 250px;
  padding-left: 10px;
  padding-right: 10px;
}

.qr-list-item {
  padding-left: 10px;
}

.qr-has-pos {
  color: lightgray;
}

.qr-no-pos:hover {
  text-decoration: underline;
}

.add-marker-icon {
  width: 20px;
  max-width: 20px;
  margin-bottom: 4px;
  margin-right: 3px;
  padding-left: 2px;
  color: var(--report-red) !important;
}
</style>
