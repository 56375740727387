<!-- eslint-disable no-console -->
<template>
  <v-card style="width: 75vw; margin: 32px auto">
    <div class="d-flex align-start" style="padding: 24px;">
      <h2 class="mr-auto">QR Codes</h2>
      <ExportButton v-if="isadministrator || issuperadministrator" :table-items="qritems" :table-type="'qr_codes'"
        :export-message="'Export List'"></ExportButton>
      <Tooltip :message="tooltipMessage"></Tooltip>
    </div>
    <v-row class="d-flex align-center mx-5 mb-3">
      <v-col cols=8>
        <v-text-field v-model="search" label="Search" hide-details clearable></v-text-field>
      </v-col>
      <v-col cols=3>
        <v-btn color="white" @click="newqr()"><v-icon medium class="py-2 mr-2">mdi-plus</v-icon> New</v-btn>
      </v-col>
    </v-row>
    <v-data-table dense :search="search" :headers="getQrHeaders" :custom-filter="filterIssuesByInventoryId"
      :items="qritems" ref="qr_data_table" class="elevation-1">
      <template #[`item.url`]="{ value }">
        <a :href="value" target="_blank">{{ value }}</a>
      </template>
      <template #[`item.qrUrl`]="{ value }">
        <a :href="value" target="_blank"><v-icon small class="py-2 mr-1">mdi-open-in-new</v-icon></a>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div style="display: flex;">
          <div style=" margin-right: 8px;">
            <v-icon @click="editItem(item); qrCategoryChanged = false;" color="grey-darken-1" size="30px"
              class="pa-2">mdi-pencil</v-icon>
          </div>
          <div style="margin: auto 0  ; margin-right: 8px;">
            <v-icon @click="showcopydialog(item)" color="grey-darken-1" size="24px"
              class="pa-2">mdi-file-multiple</v-icon>
          </div>
          <div style="">
            <v-icon @click="deleteItem(item)" color="grey-darken-1" size="30px" class="pa-2">mdi-delete</v-icon>
          </div>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="copydialog" max-width="500px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="headline">Generate copy(s) of {{ qrname }}</span>
          </v-card-title>
          <v-col cols="3">
            <v-text-field ref="copyamount" v-model="copyamount" label="No. copies" type="number"></v-text-field>
            <!-- This text field prevents default form behavior, where the page is reloaded when pressing enter in a form that has only one text field -->
            <v-text-field style="display: none;"></v-text-field>
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile outlined class="mb-3" @click="copydialog = false"><v-icon small
                class="py-2 mr-1">mdi-close</v-icon>
              Cancel</v-btn>
            <v-btn tile outlined class="mb-3 mr-3" :disabled="copyamount > 999 || copyamount < 1"
              @click="generatecopies()"><v-icon small class="py-2 mr-1">mdi-content-save</v-icon>
              Create copies</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="800px" ref="maindialog">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            this.editedIndex === -1 ? "New" : "Edit"
            }} - QR Code</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="mainform" v-model="isFormValid">
              <v-row>
                <v-col cols="3" v-if="islegacy">
                  <v-text-field required ref="qrid" v-model="editedItem.qrString" :rules="qrRules" hide-details
                    label="QRID"></v-text-field>
                </v-col>
                <v-col cols="3" v-else>
                  <v-text-field required ref="qrid" v-model="editedItem.qrWorkspaceId" :rules="qrRules" type="number"
                    hide-details min="1" label="QRID"></v-text-field>
                </v-col>
                <v-col cols="9">
                  <v-text-field v-model="editedItem.description" label="Description" hide-details></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" @click="qrCategoryChanged = true">
                  <v-combobox v-model="selectedQrCategory" :items="qrCategories" item-value="id" hide-details
                    item-title="description" required :rules="requiredRules" label="Category">
                    <v-list-item v-bind="item" :class="item.description === 'Uncategorized' &&
                      'uncategorized-list-item'
                      ">
                      {{ item.description }}
                    </v-list-item>
                  </v-combobox>
                </v-col>
                <v-col cols="4">
                  <v-combobox v-model="selectedLocation" :items="locations" auto-select-first item-value="id"
                    hide-details item-title="name" label="Location" @change="clearCoordinates"
                    ref="locationcombobox"></v-combobox>
                </v-col>
                <v-col cols="1" style="display: flex; padding-left: 0px;">
                  <v-icon v-if="selectedLocation && selectedLocation.imageId" @click="imageDialog = true" size="30px"
                    :disabled="editedItem.latLng === null"
                    style="color: var(--report-red); margin: auto 0; position: relative;">mdi-map-marker-radius</v-icon>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="editedItem.position" label="Location description" hide-details></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <v-col v-if="inventoryTypesText.length > 0" cols="7">
              <div class="typeinfo">{{ inventoryTypesText }}</div>
            </v-col>
            <v-spacer></v-spacer>
            <v-row>
              <v-col cols="9">
                <span class="headline">Inventory</span>
              </v-col>
              <v-col cols="3">
                <v-btn tile outlined @click="newqrdetail()" :disabled="!isFormValid"><v-icon medium
                    class="py-2 mr-2">mdi-plus</v-icon>New</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table dense :headers="qrdetailheaders" :items="qrDetailItemsWithKey" hide-default-footer
                  disable-pagination item-key="key" class="elevation-1">
                  <template v-slot:[`item.action`]="{ item }">
                    <div style="display: grid; grid-template-columns: 33% 33% 33% ;">
                      <v-icon size="25px" class="pa-2" @click="editqrdetail(item)"
                        color="grey-darken-1">mdi-pencil</v-icon>
                      <v-icon size="25px" class="pa-2" @click="deleteqrdetail(item)"
                        color="grey-darken-1">mdi-delete</v-icon>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <template v-if="useForms">
              <v-row>
                <v-col cols="9">
                  <span class="headline">Forms</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table dense :headers="formHeaders" :items="formObject" hide-default-footer disable-pagination
                    class="elevation-1">
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ item.description }}</td>
                        <td v-if="item.description === this.contactRequest">
                          <v-switch @update:model-value="switchContactRequestActive" v-model="item.isActive"
                            hide-details inset color="green"></v-switch>
                        </td>
                        <td v-if="item.description === this.feedback">
                          <v-switch @update:model-value="switchFeedbackActive" v-model="item.isActive" hide-details
                            inset color="green"></v-switch>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </template>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class=" mb-3" @click="close"><v-icon small class="py-2 mr-1">mdi-close</v-icon>
            Cancel</v-btn>
          <v-btn class="mb-3 mr-3" @click="save" :disabled="!isFormValid"><v-icon small
              class="py-2 mr-1">mdi-content-save</v-icon>
            Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="imageDialog" max-width="60vw" eager>
      <v-card>
        <v-card-title>
          <span class="headline m-b-6">QR Location</span>
        </v-card-title>
        <v-container>
          <div class="map-area-general">
            <MapComponent v-if="
              selectedLocation != null &&
              selectedLocation.imageName &&
              imageDialog" :qrId="editedItem.id" :name="selectedLocation.imageName" :marker-draggable="true"
              :qrs="[{ qrId: editedItem.id, qrString: editedItem.qrString === null ? editedItem.qrWorkspaceId : editedItem.qrString, qrDescription: editedItem.description, latLng: editedItem.latLng }]"
              @updateLocation="updateLocationFromChild" />
          </div>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile outlined class="mb-3 mr-3" @click="imageDialog = false"><v-icon small
              class="py-2 mr-1">mdi-close</v-icon>
            Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="qrdetaildialog" max-width="600px" eager>
      <v-card>
        <v-card-title>
          <span class="headline">{{
            this.editedqrdetail.id === 0
              ? "New - Inventory"
              : "Edit - Inventory"
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="isFormValidDetail" ref="qrdetailform">
              <v-row>
                <v-col cols="12">
                  <v-combobox v-model="editedqrdetailselected" :items="inventoryitems" item-value="id"
                    item-title="description" required :rules="requiredRules" label="Item">
                  </v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field disabled v-model="editedqrdetail.typeDescription"
                    label="Type description"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="editedqrdetail.aliasTypeDescription"
                    label="Custom type description"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile outlined class="mb-3" @click="qrdetaildialog = false"><v-icon small
              class="py-2 mr-1">mdi-close</v-icon>
            Cancel</v-btn>
          <v-btn tile outlined class="mb-3 mr-3" @click="saveqrdetails()" :disabled="!isFormValidDetail"><v-icon small
              class="py-2 mr-1">mdi-content-save</v-icon>
            Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { HTTP } from "@/plugins/backendAPI";
import { mapGetters } from "vuex";
import MapComponent from "../../components/MapComponent.vue";
import Tooltip from "../../components/HelpTooltip.vue";
import ExportButton from "../../components/ExportButton.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "home",
  components: {
    MapComponent,
    Tooltip,
    ExportButton,
  },
  data() {
    return {
      contactRequest: "Contact request",
      feedback: "Feedback",

      formDescription: "",
      formObject: [],

      qrCategoryChanged: false,
      qrCategoryReverted: false,

      dialog: false,
      qrdetaildialog: false,
      imageDialog: false,

      enableEscClear: true,
      qrname: "",
      copydialog: false,
      search: this.$route.query.inventoryId,
      defaultWorkspace: "",

      qritems: [],
      searchedItems: [],
      qrdetailheaders: [
        { title: "Name", key: "description" },
        { title: "Type description", key: "typeDescription" },
        { title: "Custom type description", key: "aliasTypeDescription" },
        { title: "Edit", key: "action", sortable: false, width: 120 },
      ],
      formHeaders: [
        { title: "Description", key: "description", sortable: false },
        { title: "Active", key: "isActive", sortable: false, width: 150 },
      ],
      editedItem: {},
      editedIndex: null,
      locations: [],
      selectedLocation: {},
      qrCategories: [],
      selectedQrCategory: null,
      copyamount: null,
      selectedqr: null,
      qrdetailitems: [],
      editedqrdetail: {},
      editedqrdetailselected: {},
      inventoryitems: [],
      inventoryTypesText: "",
      inventoryTypesByCategory: [],
      defaultForms: [],
      isContactRequestActive: null,
      isFeedbackActive: null,

      footerProps: { "items-per-page-options": [20, 50, 100] },
      modellitems: {},
      valid: true,
      isFormValid: false,
      isFormValidDetail: false,
      qrRules: [
        (v) => !!v || "QRID is required",
        (v) =>
          /^[a-öA-Ö0-9]+$/.test(v) ||
          "QRID can only contain letters and numbers",
      ],
      requiredRules: [(v) => !!v || "Field is required"],

      sortHandler: {},
      sortOptions: {
        sortBy: [],
        sortDesc: [],
      },

      tooltipMessage: [
        "QR Codes",
        'This section allows you to manage QR codes. Click "New" to add a new code. Edit existing codes by clicking the edit icon (pen), duplicate a code with the duplicate icon, or delete it with the trash icon. All settings of the listed QR codes are searchable, such as a device name or location.',
      ],
    };
  },
  computed: {
    ...mapGetters([
      "isauthorized",
      "isadministrator",
      "issuperadministrator",
      "sort",
      "workspaceid",
      "islegacy",
      "workspaceitems",
      "useForms"
    ]),
    qrDetailItemsWithKey() {
      return this.qrdetailitems.map((item, index) => ({
        key: index,
        ...item,
      }));
    },
    getQrHeaders() {
      return [
        ...(this.islegacy
          ? [{ title: "QRID", align: "left", key: "qrString" }]
          : [{ title: "QRID", align: "left", key: "qrWorkspaceId" }]),
        { title: "Category", key: "qrCategoryDescription" },
        { title: "Description", key: "description", width: "25%" },
        { title: "Location", key: "location", width: "25%" },
        { title: "Url", key: "url", sortable: false },
        { title: "QR url", key: "qrUrl", width: 90, sortable: false },
        { title: "Edit", key: "action", sortable: false, width: 180 },
      ];
    },
  },
  created() {
    this.getqr(this.workspaceid);
    this.sortOptions = this.sort.qrSort || this.sortOptions;
  },
  mounted() {
    document.addEventListener("keydown", this.clearSearchEsc);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.clearSearchEsc);
  },
  watch: {
    dialog(val) {
      val || this.close();
      this.inventoryTypesText = "";

      if (val) this.enableEscClear = false;
      if (!val) {
        setTimeout(() => {
          this.enableEscClear = true;
        }, 100);
      }
    },
    workspaceid(newValue) {
      this.getqr(newValue)
    },
    editedqrdetailselected(val) {
      if (!val) return;

      var self = this;
      HTTP.get("/qr/getinventorytype/" + val.id).then((response) => {
        self.editedqrdetail.typeDescription = response.data;
        self.editedqrdetailselected.typeDescription = response.data;
      }).catch(() => { })
    },
    selectedQrCategory(val, oldVal) {
      if (!val) return;
      var self = this;
      self.editedItem.qrCategoryDescription = val.description;
      const isUncategorized = val.description.toString() === "Uncategorized";

      if (self.qrCategoryReverted) {
        self.qrCategoryReverted = false;
        return;
      }

      if (!self.qrCategoryChanged) {
        self.getInventoryTypes(val.id);
      } else {
        if (oldVal && self.qrdetailitems.length > 0 && !isUncategorized) {
          this.$root.$ConfirmDialog
            .open(
              "Change QR Category",
              "All current items will be removed. <br><br> <strong>Are you sure?</strong>",
              { color: "red" }
            )
            .then((response) => {
              if (response) {
                self.getInventoryTypes(val.id);
                self.qrdetailitems = [];
              } else {
                self.selectedQrCategory = oldVal;
                self.qrCategoryReverted = true;
              }
            });
        } else {
          self.getInventoryTypes(val.id);
        }
      }
    },
    qrdetaildialog(val) {
      if (!val) {
        setTimeout(() => {
          this.$refs.qrid.focus();
        }, 100);
      }
    },
    imageDialog(val) {
      if (!val) {
        setTimeout(() => {
          this.$refs.qrid.focus();
        }, 100);
      }
    },
    sortHandler: {
      deep: true,
      handler(obj) {
        const objSort = { sortBy: obj.sortBy, sortDesc: obj.sortDesc };

        if (JSON.stringify(objSort) !== JSON.stringify(this.sortOptions)) {
          const newObj = this.sort;
          newObj.qrSort = objSort;
          this.$store.dispatch("updateSort", newObj);
        }
      },
    },
  },
  methods: {
    filterIssuesByInventoryId(value, search, item) {
      for (let index = 0; index < item.raw.inventoryItemIds.length; index++) {
        if (item.raw.inventoryItemIds[index] == search) {
          return true
        }
      }
      return false;
    },
    getqr(workspaceid) {
      var self = this;
      if (Number(workspaceid) === -1) {
        return
      }
      HTTP.get("/qr/get/" + workspaceid).then((response) => {
        self.defaultWorkspace = response.data.defaultWorkspace;
        self.qritems = response.data.qrs;
      }).catch(() => {
        self.qritems = []
        self.defaultWorkspace = -1
      })
    },
    editItem(item) {
      var self = this;
      self.editedIndex = self.qritems.findIndex((q) => q.id === item.id);
      HTTP.get("/qr/edit/" + item.id).then((response) => {
        self.editedItem = response.data.qr;
        self.qrCategories = response.data.qrCategories;
        self.qrdetailitems = response.data.qrDetails;
        self.selectedQrCategory = self.qrCategories.find(
          (e) => e.id == self.editedItem.qrCategoryId
        );
        self.locations = response.data.locations;
        self.selectedLocation =
          self.locations.find((l) => l.id === self.editedItem.locationId) ||
          null;

        this.formObject = [
          {
            description: this.contactRequest,
            isActive: this.editedItem.isContactRequestActive,
          },
          {
            description: this.feedback,
            isActive: this.editedItem.isFeedbackActive,
          },
        ]
        this.isFeedbackActive = this.editedItem.isFeedbackActive
        this.isContactRequestActive = this.editedItem.isContactRequestActive
      }).catch(() => { })
      this.dialog = true;
      setTimeout(() => {
        this.$refs.qrid.focus();
      }, 200);
    },
    editqrdetail(item) {
      var self = this;
      self.editedqrdetail = Object.assign(
        {},
        self.qrdetailitems.find((q) => q.id === item.id)
      );
      const isUncategorized =
        self.editedItem.qrCategoryDescription === "Uncategorized";
      if (this.workspaceid === -1) {
        return
      }
      HTTP.get(
        "/qr/getinventory/" +
        self.selectedQrCategory.id +
        "/" +
        isUncategorized +
        "/" +
        this.workspaceid
      ).then((response) => {
        self.inventoryitems = response.data;
        self.editedqrdetailselected = self.inventoryitems.find(
          (i) => i.id === self.editedqrdetail.inventoryId
        );
      }).catch(() => { })
      this.qrdetaildialog = true;
    },
    showcopydialog(item) {
      this.qrname = item.description;
      this.selectedqr = item;
      this.copydialog = true;
      setTimeout(() => {
        this.$refs.copyamount.focus();
      }, 200);
    },
    generatecopies() {
      this.copydialog = false;

      HTTP.post("/qr/copy", {
        id: this.selectedqr.id,
        copycount: this.copyamount,
      }).then(() => {
        this.getqr(this.workspaceid);
      }).catch(() => { })
    },
    getmodellitems() {
      HTTP.get("/inventory/getallmodeller").then((response) => {
        this.modellitems = response.data;
      }).catch(() => { })
    },
    getLocations() {
      if (this.workspaceid === -1) {
        return
      }
      HTTP.get("/location/get/" + this.workspaceid).then((response) => {
        this.locations = response.data;
      }).catch(() => { })
    },
    deleteItem(item) {
      const index = this.qritems.indexOf(item);
      this.$root.$ConfirmDialog
        .open("Delete item", "Are you sure?", { color: "red" })
        .then((response) => {
          if (response) {
            HTTP.post("/qr/delete", { id: item.id })
              .then(() => {
                this.qritems.splice(index, 1);
              })
              .catch((e) => {
                this.$store.dispatch("showError", e.response.data);
              });
          }
        });
    },
    deleteqrdetail(item) {
      const qrDetailItems = this.qrDetailItemsWithKey
      const qrDetailItem = qrDetailItems.find(qdi => qdi.key == item.key)
      this.qrdetailitems.splice(qrDetailItem.key, 1);
    },
    close() {
      this.dialog = false;
    },
    newqr() {
      var self = this;
      if (self.workspaceid == -1) {
        self.$store.dispatch(
          "showError",
          "No workspace selected.<br/><br/>Please make sure you have created at least one workspace and that it is selected."
        );
        return;
      }
      HTTP.get("/qr/new/" + this.workspaceid).then((response) => {
        self.selectedLocation = null;
        self.editedItem = response.data.qr;
        self.editedItem.workspaceid = this.workspaceid;
        self.isContactRequestActive = response.data.qr.isContactRequestActive;
        self.isFeedbackActive = response.data.qr.isFeedbackActive;
        self.editedIndex = -1;
        self.qrCategories = response.data.qrCategories;
        response.data.qrCategories.find(qrc => qrc.description === "Uncategorized") !== null ? self.selectedQrCategory = response.data.qrCategories.find(qrc => qrc.description === "Uncategorized") : self.selectedQrCategory = null;
        self.qrdetailitems = response.data.qrDetails;
        self.dialog = true;
        self.getLocations();
        if (self.$refs.mainform) {
          self.$refs.mainform.resetValidation();
        }
        this.formObject = [
          {
            description: this.contactRequest,
            isActive: this.editedItem.isContactRequestActive,
          },
          {
            description: this.feedback,
            isActive: this.editedItem.isFeedbackActive,
          },
        ]
        setTimeout(() => {
          self.$refs.qrid.focus();
        }, 200);
      }).catch(() => { })
    },
    newqrdetail() {
      var self = this;
      const isUncategorized =
        self.editedItem.qrCategoryDescription === "Uncategorized";
      HTTP.get("/qr/newqrdetail").then((response) => {
        self.editedqrdetail = response.data;
        self.editedqrdetailselected = null;
        self.getInventoryByType(self.selectedQrCategory.id, isUncategorized);
        self.qrdetaildialog = true;
        if (this.$refs.qrdetailform) this.$refs.qrdetailform.resetValidation();
      }).catch(() => { })
    },
    save() {
      var self = this;
      self.$refs.locationcombobox.blur();

      self.$nextTick(() => {
        let locationName = "";

        self.editedItem.qrCategoryId = self.selectedQrCategory.id;
        self.editedItem.isContactRequestActive = this.isContactRequestActive;
        self.editedItem.isFeedbackActive = this.isFeedbackActive;

        if (self.selectedLocation) {
          if (self.selectedLocation.id) {
            locationName = self.selectedLocation.name;
            self.editedItem.newLocation = null;
            self.editedItem.locationId = self.selectedLocation.id;
          } else {
            locationName = self.selectedLocation;
            self.editedItem.locationId = null;
            self.editedItem.newLocation = self.selectedLocation;
            self.editedItem.workspaceId = self.workspaceid;
          }
        } else {
          self.editedItem.locationId = null;
        }

        if (self.editedItem.position) {
          locationName !== ""
            ? (locationName += " - " + self.editedItem.position)
            : (locationName = self.editedItem.position);
        } else {
          self.editedItem.position = null;
        }

        HTTP.post("/qr/save", {
          qr: self.editedItem,
          qrDetails: self.qrdetailitems,
          qrCategories: [],
          locations: [],
        })
          .then((response) => {
            const qrUrl = self.islegacy
              ? self.editedItem.qrString
              : `${self.editedItem.workspaceId}/${self.editedItem.qrWorkspaceId}`;

            if (self.editedIndex > -1) {
              var currentitem = self.qritems[self.editedIndex];
              currentitem.qrString = self.editedItem.qrString;
              currentitem.description = self.editedItem.description;
              currentitem.location = locationName;
              currentitem.qrCategoryDescription = self.qrCategories.find(
                (t) => t.id === self.editedItem.qrCategoryId
              ).description;
              // eslint-disable-next-line
              currentitem.url = process.env.VUE_APP_CLIENTURL + "/m/" + qrUrl;
              currentitem.qrUrl =
                // eslint-disable-next-line no-undef
                process.env.VUE_APP_CLIENTURL + "/qrimage/" + qrUrl;
            } else {
              self.editedItem.id = response.data;
              self.editedItem.qrCategoryDescription = self.qrCategories.find(
                (t) => t.id === self.editedItem.qrCategoryId
              ).description;
              self.editedItem.location = locationName;
              self.editedItem.url =
                // eslint-disable-next-line no-undef
                process.env.VUE_APP_CLIENTURL + "/m/" + qrUrl;
              self.editedItem.qrUrl =
                // eslint-disable-next-line no-undef
                process.env.VUE_APP_CLIENTURL + "/qrimage/" + qrUrl;
              self.qritems.push(self.editedItem);
            }
          })
          .catch((e) => {
            this.$store.dispatch("showError", e.response.data);
          })
          .then(() => {
            this.dialog = false;
          });
      });
    },
    saveqrdetails() {
      var currentIndex = this.qrdetailitems.findIndex(
        (q) => q.id == this.editedqrdetail.id
      );
      if (currentIndex === -1) {
        this.editedqrdetail.id = -1;
        this.editedqrdetail.inventoryId = this.editedqrdetailselected.id;
        this.editedqrdetail.description =
          this.editedqrdetailselected.description.split("(")[0];
        this.qrdetailitems.push(this.editedqrdetail);
      } else {
        this.qrdetailitems[currentIndex].inventoryId =
          this.editedqrdetailselected.id;
        this.qrdetailitems[currentIndex].description =
          this.editedqrdetailselected.description;
        this.qrdetailitems[currentIndex].typeDescription =
          this.editedqrdetailselected.typeDescription;
        this.qrdetailitems[currentIndex].aliasTypeDescription =
          this.editedqrdetail.aliasTypeDescription;
      }
      this.qrdetaildialog = false;
    },
    getInventoryByType(qrCategoryId, isUncategorized) {
      var self = this;
      if (this.workspaceid === -1) {
        return
      }
      HTTP.get(
        "/qr/getinventory/" +
        qrCategoryId +
        "/" +
        isUncategorized +
        "/" +
        self.workspaceid
      ).then((response) => {
        self.inventoryitems = response.data;
      }).catch(() => { })
    },
    getInventoryTypes(id) {
      this.inventoryTypesText = "";
      this.inventoryTypesByCategory = [];

      HTTP.get("/qrcategory/getinventorytypes/" + id).then((response) => {
        for (var i = 0; i < response.data.length; i++) {
          if (response.data[i].qrCategory === id) {
            this.inventoryTypesByCategory.push(response.data[i].inventoryType);
          }
        }
        for (var j = 0; j < this.inventoryTypesByCategory.length; j++) {
          if (j <= this.inventoryTypesByCategory.length - 2) {
            this.inventoryTypesText += this.inventoryTypesByCategory[j] + ", ";
          } else {
            this.inventoryTypesText += this.inventoryTypesByCategory[j];
          }
        }
      }).catch(() => { })
    },
    filter(value, search, item) {
      return Object.values(item).some((prop) => {
        return String(prop).toLowerCase().includes(search.toLowerCase());
      });
    },
    switchContactRequestActive(item) {
      this.isContactRequestActive = item;
    },
    switchFeedbackActive(item) {
      this.isFeedbackActive = item;
    },
    clearSearchEsc(e) {
      if (e.keyCode === 27 && this.enableEscClear) {
        this.search = "";
      } else if (e.keyCode === 13 && this.copydialog && this.copyamount > 0 && this.copyamount < 999) {
        this.generatecopies()
      }
    },
    updateLocationFromChild(value) {
      this.editedItem.latLng = value;
    },
    clearCoordinates() {
      this.editedItem.latLng = null;
    },
  },
};
</script>
