import { inject } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import AdminIssues from "@/views/Admin/AdminIssues.vue";
import AdminQr from "@/views/Admin/AdminQr.vue";
import AdminQrCategory from "@/views/Admin/AdminQrCategory.vue";
import AdminInventory from "@/views/Admin/AdminInventory.vue";
import AdminStatistics from "@/views/Admin/AdminStatistics.vue";
import AdminUsers from "@/views/Admin/AdminUsers.vue";
import AdminOrganization from "@/views/Admin/AdminOrganization.vue";
import AdminWorkspace from "@/views/Admin/AdminWorkspace.vue";
import Login from "@/views/Login";
import Logout from "@/views/Logout";
import RecoverPassword from "@/views/RecoverPassword";
import ChangePassword from "@/views/ChangePassword";
import MobileSelect from "@/views/MobileSelect";
import MobileReport from "@/views/MobileReport";
import MobileForm from "@/views/MobileForm";
import QRImage from "@/views/QRImage";
import MobileEdit from "@/views/MobileEdit";
import MobileEditSuccess from "@/views/MobileEditSuccess";
import AdminForm from "@/views/Admin/AdminForm";
import AdminLocation from "@/views/Admin/AdminLocation";
import MapView from "@/views/MapView";
import MobileLogin from "../views/MobileLogin.vue";
import MobileMain from "../views/MobileMain.vue";
import MobileRequestSuccess from "../views/MobileRequestSuccess.vue";
import AdminSystemInventoryType from "../views/Admin/AdminSystemInventoryType.vue";
import AdminMain from "../views/Admin/AdminMain.vue";
import AdminWelcome from "../views/Admin/AdminWelcome.vue";
import store from "../store";

const routes = [
  {
    path: "/",
    redirect: "/issues",
  },

  {
    path: "/",
    component: AdminMain,
    meta: { conditionalRoute: true },
    children: [
      {
        path: "issues",
        name: "Issues",
        component: AdminIssues,
        props: true,
        meta: { conditionalRoute: true },
      },
      {
        path: "qr",
        name: "qr",
        component: AdminQr,
        props: true,
        meta: { conditionalRoute: true },
      },
      {
        path: "qrcategory",
        name: "Qrcategory",
        component: AdminQrCategory,
        meta: { conditionalRoute: true },
      },
      {
        path: "form",
        name: "Form",
        component: AdminForm,
        meta: { conditionalRoute: true },
        beforeEnter: () => {
          const store = inject("store");
          if (
            localStorage.useForms === "false" ||
            localStorage.useForms === "undefined" ||
            localStorage.useForms === false ||
            localStorage.useForms === undefined
          ) {
            console.log(localStorage);
            store.dispatch("showError", "Your organization has disabled forms. <br><br> Redirecting to issues page...");
            return { path: "/issues" };
          }
        },
      },
      {
        path: "location",
        name: "Location",
        component: AdminLocation,
        meta: { conditionalRoute: true },
      },
      {
        path: "inventory",
        name: "Inventory",
        component: AdminInventory,
        meta: { conditionalRoute: true },
      },
      {
        path: "inventorytype/system",
        name: "SystemInventoryType",
        component: AdminSystemInventoryType,
        meta: { conditionalRoute: true },
      },
      {
        path: "qrimage/:qrstring",
        name: "QRImageLegacy",
        props: true,
        component: QRImage,
        meta: { conditionalRoute: true },
      },
      {
        path: "qrimage/:workspaceid/:qrworkspaceid",
        name: "QRImage",
        props: true,
        component: QRImage,
        meta: { conditionalRoute: true },
      },
      {
        path: "statistics",
        name: "Statistics",
        component: AdminStatistics,
        meta: { conditionalRoute: true },
      },
      {
        path: "users",
        name: "Users",
        component: AdminUsers,
        meta: { conditionalRoute: true },
      },
      {
        path: "organization",
        name: "Organization",
        component: AdminOrganization,
        meta: { conditionalRoute: true },
      },
      {
        path: "workspace",
        name: "Workspace",
        component: AdminWorkspace,
        meta: { conditionalRoute: true },
      },
      {
        path: "/welcome",
        name: "AdminWelcome",
        props: true,
        component: AdminWelcome,
        beforeEnter: () => {
          if (store.getters.isSSOLogin !== "true" && store.getters.isSSOLogin !== true) {
            return { path: "/issues" };
          } else if (
            store.getters.workspaceid !== -1 &&
            store.getters.workspaceid !== "-1" &&
            store.getters.workspaceid !== 0 &&
            store.getters.workspaceid !== "0"
          ) {
            return { path: "/issues" };
          }
        },
      },
    ],
  },
  {
    path: "/m/:qrstring",
    props: true,
    component: MobileMain,
    meta: { conditionalRoute: true },
    children: [
      {
        path: "",
        props: true,
        name: "MobileSelectLegacy",
        component: MobileSelect,
        meta: { conditionalRoute: true },
      },
      {
        path: "login",
        props: true,
        name: "MobileLegacyLogin",
        component: MobileLogin,
        meta: { conditionalRoute: true },
      },
      {
        path: "report/:qrdetailid",
        props: true,
        name: "MobileLegacyReport",
        component: MobileReport,
        meta: { conditionalRoute: true },
      },
      {
        path: "form/:formtype",
        props: true,
        name: "MobileLegacyForm",
        component: MobileForm,
        meta: { conditionalRoute: true },
      },
      {
        path: "success",
        name: "MobileLegacyRequestSuccess",
        component: MobileRequestSuccess,
        meta: { conditionalRoute: true },
      },
    ],
  },
  {
    path: "/m/:workspaceid/:qrworkspaceid",
    props: true,
    component: MobileMain,
    meta: { conditionalRoute: true },
    children: [
      {
        path: "",
        props: true,
        name: "MobileSelect",
        component: MobileSelect,
        meta: { conditionalRoute: true },
      },
      {
        path: "login",
        props: true,
        name: "MobileLogin",
        component: MobileLogin,
        meta: { conditionalRoute: true },
      },
      {
        path: "report/:qrdetailid",
        props: true,
        name: "MobileReport",
        component: MobileReport,
        meta: { conditionalRoute: true },
      },
      {
        path: "form/:formtype",
        props: true,
        name: "MobileForm",
        component: MobileForm,
        meta: { conditionalRoute: true },
      },
      {
        path: "success",
        name: "MobileRequestSuccess",
        component: MobileRequestSuccess,
        meta: { conditionalRoute: true },
      },
    ],
  },
  {
    path: "/m/edit/:editType/success",
    name: "MobileEditSuccess",
    props: true,
    component: MobileEditSuccess,
    meta: { conditionalRoute: true },
  },
  {
    path: "/m/edit/:workspaceid/:qrworkspaceid",
    name: "MobileEdit",
    props: true,
    component: MobileEdit,
    meta: { conditionalRoute: true },
  },
  {
    path: "/m/edit/:qrstring",
    name: "MobileEditLegacy",
    props: true,
    component: MobileEdit,
    meta: { conditionalRoute: true },
  },
  {
    path: "/displaymap/:imageid/:latlng",
    name: "MapPage",
    props: true,
    component: MapView,
  },
  {
    path: "/login",
    name: "Login",
    props: true,
    component: Login,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/recoverpassword",
    name: "RecoverPassword",
    component: RecoverPassword,
  },
  {
    path: "/changepassword/:guid",
    name: "ChangePassword",
    props: true,
    component: ChangePassword,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to) => {
  const store = inject("store");
  if (
    to.name !== "Logout" &&
    to.name !== "Login" &&
    to.name !== "ChangePassword" &&
    to.name !== "RecoverPassword" &&
    to.name !== "MapPage" &&
    to.name !== "MobileSelectLegacy" &&
    to.name !== "MobileLegacyLogin" &&
    to.name !== "MobileLegacyReport" &&
    to.name !== "MobileLegacyForm" &&
    to.name !== "MobileLegacyRequestSuccess" &&
    to.name !== "MobileSelect" &&
    to.name !== "MobileLogin" &&
    to.name !== "MobileReport" &&
    to.name !== "MobileForm" &&
    to.name !== "MobileRequestSuccess"
  ) {
    // console.log(checkAuth("auth"));
    // console.log(to);
    // console.log(store.getters.isSSOLogin);
    // console.log(store.getters.workspaceid);
    if (
      checkAuth("auth") &&
      (store.getters.isSSOLogin === "true" || store.getters.isSSOLogin === true) &&
      (store.getters.workspaceid === "-1" ||
        store.getters.workspaceid === -1 ||
        store.getters.workspaceid === "0" ||
        store.getters.workspaceid === 0) &&
      to.name !== "AdminWelcome"
    ) {
      console.log(to);
      console.log(store.getters.isSSOLogin);
      console.log(store.getters.workspaceid);
      return { name: "AdminWelcome" };
    } else if (!checkAuth("auth")) {
      return { name: "Login" };
    }
  }
  if (to.name === "Login" && checkAuth("auth")) {
    return { name: "Issues" };
  }
  if ((to.name === "Organization" || to.name === "SystemInventoryType") && !checkAuth("super-admin")) {
    store.dispatch(
      "showError",
      "You're account is not authorized for this page. <br><br> Redirecting to issues page..."
    );
    return { name: "Issues" };
  }
  if ((to.name === "Workspace" || to.name === "Users") && !checkAuth("admin")) {
    store.dispatch(
      "showError",
      "You're account is not authorized for this page. <br><br> Redirecting to issues page..."
    );
    return { name: "Issues" };
  }
});

function checkAuth(authLevel) {
  if (authLevel === "auth") {
    if (
      localStorage.isauthorized === "false" ||
      localStorage.isauthorized === false ||
      localStorage.isauthorized === "undefined" ||
      localStorage.isauthorized === undefined
    ) {
      return false;
    }
  } else if (authLevel === "admin") {
    if (
      localStorage.isadministrator === "false" ||
      localStorage.isadministrator === false ||
      localStorage.isadministrator === "undefined" ||
      localStorage.isadministrator === undefined
    ) {
      if (
        localStorage.issuperadministrator === "false" ||
        localStorage.issuperadministrator === false ||
        localStorage.issuperadministrator === "undefined" ||
        localStorage.issuperadministrator === undefined
      ) {
        return false;
      }
    }
  } else if (authLevel === "super-admin") {
    if (
      localStorage.issuperadministrator === "false" ||
      localStorage.issuperadministrator === false ||
      localStorage.issuperadministrator === "undefined" ||
      localStorage.issuperadministrator === undefined
    ) {
      return false;
    }
  }
  return true;
}

export default router;
