<template>
  <!-- Name category ' Uncategorized' to be listed first among categories + get all inventories from all categories -->
  <v-card style="width: 75vw; margin: 32px auto">
    <div class="d-flex align-start" style="padding: 24px;">
      <h2 class="mr-auto">QR Category</h2>
      <Tooltip :message="tooltipMessage"></Tooltip>
    </div>
    <v-row class="d-flex align-center mx-5 mb-3">
      <v-col cols=8>
        <v-text-field v-model="search" label="Search" clearable hide-details></v-text-field>
      </v-col>
      <v-col cols=3>
        <v-btn color="white" @click="newitem()">
          <v-icon medium class="py-2 mr-2">mdi-plus</v-icon> New
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table dense :search="search" :headers="headers" :items="tableItems" :sort-by.sync="sortOptions.sortBy"
      :sort-desc.sync="sortOptions.sortDesc" :options.sync="sortHandler" :items-per-page="20"
      :footer-props="footerProps" class="elevation-1">
      <template v-slot:[`item.action`]="{ item }">
        <div style="display: grid; grid-template-columns: 50% 50%">
          <v-icon v-if="item.description !== 'Uncategorized'" color="grey-darken-1" size="30px" class="pa-2"
            @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon v-if="item.description !== 'Uncategorized'" color="grey-darken-1" size="30px" class="pa-2"
            @click="deleteItem(item)">mdi-delete</v-icon>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="600px">
      <v-form ref="newqrform" v-model="validEdit">
        <v-card>
          <v-card-title>
            <span class="headline">{{ this.editedItem.id === 0 ? 'New' : 'Edit' }} - QR Category</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field required :rules="requiredRules" ref="description" v-model="editedItem.description"
                    label="Description"></v-text-field>
                </v-col>
              </v-row>
              <v-spacer></v-spacer>
              <v-row>
                <v-col cols="9">
                  <span class="headline">Inventory types</span>
                </v-col>
                <v-col cols="3">
                  <v-btn tile outlined @click="newdetail()" :disabled="!validEdit"><v-icon medium
                      class="py-2 mr-2">mdi-plus</v-icon>New</v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table dense :headers="detailheaders" :items="editedDetailItemsWithKey" hide-default-footer
                    disable-pagination item-key="key" class="elevation-1">
                    <template v-slot:[`item.action`]="{ item }">
                      <div style="display: grid; grid-template-columns: 50% 50%">
                        <v-icon v-if="item.description !== 'Uncategorized'" color="grey-darken-1" size="30px"
                          class="pa-2" @click="editdetail(item)">mdi-pencil</v-icon>
                        <v-icon v-if="item.description !== 'Uncategorized'" color="grey-darken-1" size="30px"
                          class="pa-2" @click="deletedetail(item)">mdi-delete</v-icon>
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile outlined class="mb-3" @click="dialog = false"><v-icon small class="py-2 mr-1">mdi-close</v-icon>
              Cancel</v-btn>
            <v-btn tile outlined class="mb-3 mr-3" @click="save" :disabled="!validEdit"><v-icon small
                class="py-2 mr-1">mdi-content-save</v-icon> Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
      <v-dialog v-model="detaildialog" max-width="600px" eager>
        <v-form ref="detailform" v-model="validEditDetail">
          <v-card>
            <v-card-title>
              <span class="headline">{{ this.editeddetail.id === 0 ? 'New' : 'Edit' }} QR category detail</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-combobox v-model="editedQrCategoryDetailSelected" :items="getFilteredInventoryTypeItems"
                      item-value="id" item-title="description" required :rules="requiredRules" label="Item">
                    </v-combobox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn tile outlined class="mb-3" @click="detaildialog = false"><v-icon small
                  class="py-2 mr-1">mdi-close</v-icon> Cancel</v-btn>
              <v-btn tile outlined class="mb-3 mr-3" @click="savedetail()" :disabled="!validEditDetail"><v-icon small
                  class="py-2 mr-1">mdi-content-save</v-icon> Ok</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-dialog>
  </v-card>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex'
// import { EventBus } from '@/store/event-bus.js'
import Tooltip from '../../components/HelpTooltip.vue'

export default {
  components: { Tooltip, },
  data() {
    return {
      dialog: false,
      detaildialog: false,
      enableEscClear: true,
      search: '',
      footerProps: { 'items-per-page-options': [20, 50, 100] },
      headers: [
        { title: 'Description', key: 'description' },
        { title: 'Edit', key: 'action', sortable: false, width: 130 },
      ],
      detailheaders: [
        { title: 'Description', key: 'inventorytypeDescription' },
        { title: 'Edit', key: 'action', sortable: false, width: 130 },
      ],
      validEdit: false,
      validEditDetail: false,
      tableItems: [],
      editedItem: {},
      editeddetailItems: [],
      editeddetail: {},
      editedQrCategoryDetailSelected: {},
      inventorytypeItems: [],
      requiredRules: [
        v => !!v || 'Field is required',
      ],

      sortHandler: {},
      sortOptions: {
        sortBy: [],
        sortDesc: [],
      },

      tooltipMessage: [
        "QR Category",
        'Organize your item types into categories, though it\'s not mandatory, it helps if you have a large inventory. Create a category and add the item types you want to associate with that category. One type can belong to multiple categories. When you choose a category on the QR code, you will only see inventories in the selected category. '
      ],
    }
  },
  computed: {
    ...mapGetters([
      'sort',
      'workspaceid'
    ]),
    getFilteredInventoryTypeItems() {
      return this.inventorytypeItems.filter(iti => this.editeddetailItems.every(edi => edi.inventorytypeId !== iti.id))
    },
    editedDetailItemsWithKey() {
      return this.editeddetailItems.map((item, index) => ({
        key: index,
        ...item
      }))
    },
  },
  watch: {
    sortHandler: {
      deep: true,
      handler(obj) {
        const objSort = { sortBy: obj.sortBy, sortDesc: obj.sortDesc }

        if (JSON.stringify(objSort) !== JSON.stringify(this.sortOptions)) {
          const newObj = this.sort
          newObj.qrCategorySort = objSort
          this.$store.dispatch('updateSort', newObj)
        }
      }
    },
    workspaceid(newValue) {
      this.getitems(newValue);
    },
    detaildialog(val) {
      if (!val) {
        setTimeout(() => {
          this.$refs.description.focus()
        }, 100)
      }
    },
    dialog(val) {
      if (val) this.enableEscClear = false
      if (!val) {
        setTimeout(() => {
          this.enableEscClear = true
        }, 100)
      }
    },
  },
  created() {
    this.getitems(this.workspaceid);
    this.sortOptions = this.sort.qrCategorySort || this.sortOptions;
  },
  mounted() {
    document.addEventListener('keydown', this.clearSearchEsc)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.clearSearchEsc)
  },
  methods: {
    getitems(workspaceid) {
      if (Number(workspaceid) === -1) {
        return
      }
      HTTP.get('/qrcategory/get/' + workspaceid).then(response => {
        this.tableItems = response.data
      }).catch(() => {
        this.tableItems = []
      })
    },
    newitem() {
      if (this.workspaceid == -1) {
        this.$store.dispatch('showError', 'No workspace selected.<br/><br/>Please make sure you have created atleast one workspace and that it is selected.')
        return
      }
      this.editedItem = { id: 0, description: null }
      this.editedItem.workspaceid = this.workspaceid;
      this.editeddetailItems = []
      this.showDialog()
      if (this.$refs.newqrform) this.$refs.newqrform.resetValidation()
    },
    editItem(item) {
      var self = this
      HTTP.get('/qrcategory/edit/' + item.id).then(response => {
        self.editedItem = Object.assign({}, response.data.qrCategory);
        self.editedItem.workspaceid = self.workspaceid;
        self.editeddetailItems = Object.assign([], response.data.qrtypedetails);
        self.showDialog();
      })
    },
    showDialog() {
      this.dialog = true
      setTimeout(() => {
        this.$refs.description.focus()
      }, 200)
    },
    newdetail() {
      this.editdetail({ id: 0, inventorytypeDescription: null, inventorytypeId: 0 })
    },
    editdetail(item) {
      var self = this
      if (item.id != 0)
        self.editeddetail = Object.assign({}, self.editeddetailItems.find(q => q.id === item.id))
      else
        self.editeddetail = item
      if (this.workspaceid === -1) {
        return
      }
      HTTP.get('/inventorytypes/get/a/' + this.workspaceid)
        .then(response => {
          self.inventorytypeItems = response.data
          if (item.id != 0)
            self.editedQrCategoryDetailSelected = self.inventorytypeItems.find(i => i.id === self.editeddetail.inventorytypeId)
          else
            self.editedQrCategoryDetailSelected = null
          this.detaildialog = true
          if (this.$refs.detailform) this.$refs.detailform.resetValidation()
        })
    },
    deleteItem(item) {
      var self = this
      const index = self.tableItems.indexOf(item)
      this.$root.$ConfirmDialog.open('Delete item', '<strong>Are you sure?</strong>', { color: 'red' }).then((response) => {
        if (response) {
          HTTP.post('/qrcategory/delete', { id: item.id }).then(() => {
            self.tableItems.splice(index, 1)
          }).catch(e => [
            this.$store.dispatch('showError', e.response.data)
          ])
        }
      })
    },
    deletedetail(item) {
      let index = 0
      if (item.id === 0) {
        index = this.editeddetailItems.findIndex(x => x.inventorytypeId === item.inventorytypeId)
      }
      else {
        index = this.editeddetailItems.findIndex(x => x.id === item.id)
      }
      this.$root.$ConfirmDialog.open('Delete item', '<strong>Are you sure?</strong>', { color: 'red' }).then((response) => {
        if (response) {
          this.editeddetailItems.splice(index, 1)
        }
      })
    },
    save() {
      var self = this
      HTTP.post('/qrcategory/save', { qrCategory: self.editedItem, qrtypedetails: self.editeddetailItems }).then(response => {
        if (self.editedItem.id > 0) {
          var editedIndex = self.tableItems.findIndex(q => q.id === self.editedItem.id)
          self.tableItems[editedIndex].description = self.editedItem.description
        } else {
          self.editedItem.id = response.data
          self.tableItems.push(self.editedItem)
        }
      }).catch(e => {
        this.$store.dispatch('showError', 'Your changes could not be saved: ' + e.response.data)
      }).then(() => {
        this.dialog = false
      })
    },
    savedetail() {
      var currentIndex = this.editeddetail.id === 0 ?
        -1 : this.editeddetailItems.findIndex(q => q.id == this.editeddetail.id)
      if (currentIndex < 0) {
        this.editeddetail.inventorytypeId = this.editedQrCategoryDetailSelected.id
        this.editeddetail.inventorytypeDescription = this.editedQrCategoryDetailSelected.description
        this.editeddetailItems.push(this.editeddetail)
      } else {
        this.editeddetailItems[currentIndex].inventorytypeId = this.editedQrCategoryDetailSelected.id
        this.editeddetailItems[currentIndex].inventorytypeDescription = this.editedQrCategoryDetailSelected.description
      }
      this.detaildialog = false
    },
    clearSearchEsc(e) {
      if (e.keyCode === 27 && this.enableEscClear) {
        this.search = ''
      }
    },
  }
}
</script>
