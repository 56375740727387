<template>
  <v-card>
    <div v-if="this.$route.name === 'AdminWelcome'" class="appbar row justify-between align-center"
      style="display: grid;  grid-template-columns: 20% 60% 20%; min-height: 146px;">
      <div class="col-md-3">
        <img src="./../assets/service-me-logo.png" class="biglogo appbar-logo-margin">
      </div>
      <div></div>
      <div class="d-flex justify-end">
        <v-btn class="adminbutton" to="/logout"><v-icon class="mr-2">mdi-logout</v-icon>Log out</v-btn>
      </div>
    </div>
    <div v-else class="appbar row justify-between align-center"
      style="display: grid;  grid-template-columns: 20% 60% 20%">
      <div class="col-md-3">
        <button @click="goToHome">
          <img src="./../assets/service-me-logo.png" class="biglogo appbar-logo-margin">
        </button>
      </div>
      <div class="d-flex justify-center pa-4">
        <div>
          <div v-if="organizationitems.length > 0" class="justify-center"
            style="display: grid; grid-template-columns: 50% 50%">
            <v-select @update:model-value="setSelectedOrganization" :items="organizationitems"
              v-model="selectedorganizationid" item-title="name" item-value="id" label="Choose organization" solo
              style="margin-right: 12px;"></v-select>
            <v-select v-if="selectedworkspaceid >= 1 && workspaceitems.length >= 1" :items="workspaceitems"
              v-model="selectedworkspaceid" @update:model-value="setSelectedWorkspace" item-title="name" item-value="id"
              label="Choose workspace" solo style="margin-right: 12px;"></v-select>
            <v-select v-else :items="workspaceitems" @update:model-value="setSelectedWorkspace" item-title="name"
              item-value="id" label="Choose workspace" solo style="margin-right: 12px;"></v-select>
          </div>
          <div v-else>
            <v-select :items="workspaceitems" v-model="selectedworkspaceid" @update:model-value="setSelectedWorkspace"
              item-title="name" item-value="id" label="Choose workspace" solo style="margin-right: 12px;"></v-select>
          </div>
          <div class="justify-center" style="display: grid; grid-template-columns: 25% 25% 25% 25%">
            <v-btn to="/issues" style="margin-right: 12px;">
              <template v-slot:prepend>
                <v-icon>mdi-check-circle</v-icon>
              </template>
              Issues
            </v-btn>
            <v-btn to="/qr" style="margin-right: 12px;">
              <template v-slot:prepend>
                <v-icon>mdi-qrcode</v-icon>
              </template>
              Qr Codes
            </v-btn>
            <v-btn to="/inventory" style="margin-right: 12px;">
              <template v-slot:prepend>
                <v-icon>mdi-pencil</v-icon>
              </template>
              Inventory
            </v-btn>
            <v-btn to="/statistics" style="margin-right: 12px;">
              <template v-slot:prepend>
                <v-icon>mdi-chart-line</v-icon>
              </template>
              Statistics
            </v-btn>
          </div>
        </div>
      </div>
      <div class="col-md-3 d-flex justify-end">
        <div class="d-flex flex-column">
          <v-btn class="adminbutton mb-8" to="/logout"><v-icon class="mr-2">mdi-logout</v-icon>Log out</v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ props }">
              <v-btn class="adminbutton" v-bind="props"><v-icon class="mr-2">mdi-cog</v-icon>Admin</v-btn>
            </template>
            <v-list>
              <v-list-item link v-for="(item, index) in menuitems" :key="index" router :to="item.url">
                <v-list-item-title class="menuitems"><v-icon>{{ item.icon }}</v-icon> {{ item.title
                  }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>
    <v-progress-linear :active="loading" :indeterminate="loading" height="6" absolute bottom
      color="blue accent-2"></v-progress-linear>
  </v-card>
</template>
<script>

import { HTTP } from '@/plugins/backendAPI';
import { mapGetters } from 'vuex';
// import { EventBus } from '@/store/event-bus.js';

export default {
  data() {
    return {
      selectedorganizationid: 0,
      selectedworkspaceid: 0,
      menuitems: [],
      loading: false,
    }
  },
  created() {
    this.getEverything()
  },
  computed: {
    ...mapGetters([
      'workspaceid',
      'useForms',
      'legacyworkspaceid',
      'workspaceitems',
      'organizationid',
      'organizationitems',
      'isadministrator',
      'issuperadministrator',
      "isSSOLogin",
    ])
  },
  watch: {
    useForms() {
      this.menuitems = []
      this.fillmenuitems()
    },
    workspaceid(newWorkspaceId) {
      this.selectedworkspaceid = newWorkspaceId
    }
  },
  methods: {
    goToHome() {
      this.$router.push('/')
    },
    async getEverything() {
      await this.getorganizations();
      this.getworkspaces();
    },
    fillmenuitems() {
      if (this.issuperadministrator) {
        this.menuitems.push({ title: 'Organizations', icon: 'mdi-domain', url: '/organization' })
        this.menuitems.push({ title: 'System Inventory Types', icon: 'mdi-dresser-outline', url: '/inventorytype/system' })
      }
      if (this.isadministrator || this.issuperadministrator) {
        this.menuitems.push({ title: 'Users', icon: 'mdi-account', url: '/users' })
        this.menuitems.push({ title: 'Workspaces', icon: 'mdi-sitemap', url: '/workspace' })
      }
      this.menuitems.push({ title: 'QR categories', icon: 'mdi-devices', url: '/qrcategory' })
      this.menuitems.push({ title: 'Locations', icon: 'mdi-home-map-marker', url: '/location' })

      if (
        this.useForms === "true" ||
        this.useForms === true
      ) {
        this.menuitems.push({ title: 'Forms', icon: 'mdi-format-list-numbered', url: '/form' })
      }
    },
    async getorganizations() {
      var self = this;
      if (self.organizationitems.length < 1) {
        await HTTP.get('/organization/get')
          .then((response) => {
            if (response.data.length > 0) {
              const localStorageOrganizationIdExists = response.data.find(w => w.id == Number(self.organizationid))
              if (!localStorageOrganizationIdExists) {
                self.$store.dispatch('saveOrganizationId', -1)
              }
              response.data.forEach(element => {
                self.$store.dispatch('addOrganizationitem', element)
              });
            }
            this.menuitems = []
            this.fillmenuitems()
          }).catch(() => {
            this.$store.dispatch('showError', 'Oops, something went wrong. Please try again at a later time.')
          })
      }
      if (self.organizationitems.length > 0) {
        if (self.organizationid === undefined || self.organizationid === -1) {
          self.selectedorganizationid = self.organizationitems[0].id;
        } else {
          self.selectedorganizationid = Number(self.organizationid);
        }
      }
    },
    async getworkspaces() {
      var self = this;
      self.$store.dispatch('clearWorkspaceitems')
      if (self.workspaceitems.length < 1 && self.organizationid > 0) {
        await HTTP.get('/workspace/getlist/' + self.organizationid).then(response => {
          self.$store.dispatch('saveLegacyWorkspaceId', response.data.legacyWorkspaceId)
          if (response.data.workspaces.length === 0) {
            self.$store.dispatch('saveWorkspaceId', -1)
          }
          else {
            const localStorageWorkspaceIdExists = response.data.workspaces.find(w => w.id == self.workspaceid)
            if (!localStorageWorkspaceIdExists) {
              self.selectedworkspaceid = response.data.workspaces[0].id;
              self.$store.dispatch('saveWorkspaceId', response.data.workspaces[0].id)
            } else {
              self.selectedworkspaceid = Number(self.workspaceid);
            }
            response.data.workspaces.forEach(element => {
              self.$store.dispatch('addWorkspaceitem', element)
            });
          }
        }).catch(() => {
          this.$store.dispatch('showError', 'Oops, something went wrong. Please try again at a later time.')
        })
      }
    },
    // eslint-disable-next-line no-unused-vars
    setSelectedWorkspace(workspaceid) {
      this.$store.dispatch('saveWorkspaceId', workspaceid);
    },
    setSelectedOrganization(organizationid) {
      var self = this;
      self.$store.dispatch('saveOrganizationId', organizationid);
      HTTP.get(`/organization/form/c/${organizationid}`).then((response) => {
        this.$store.dispatch('doesOrganizationUseForms', response.data)
        if (!response.data && this.$route.fullPath.split("/")[1] === "form") {
          this.$store.dispatch("showError", "Your organization has disabled forms. <br><br> Redirecting to issues page...");
          this.$router.push({ name: "Issues" })
        }
        self.getworkspaces();
      }).catch(() => {
        this.$store.dispatch('doesOrganizationUseForms', false)
        if (this.$route.fullPath.split("/")[1] === "form") {
          this.$store.dispatch("showError", "Your organization has disabled forms. <br><br> Redirecting to issues page...");
          this.$router.push({ name: "Issues" })
        }
      })
    }
  }
}
</script>
<style lang="css" scoped></style>
