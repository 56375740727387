import * as msal from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "b339b56b-a477-4a97-9be1-9f28fd5d937d",
    authority: "https://login.microsoftonline.com/bf863ccd-8354-4824-b61d-ac6e97d9a7c7",
    // eslint-disable-next-line no-undef
    redirectUri: process.env.VUE_APP_ENTRA_ID_REDIRECT_URI,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.log(message);
            return;
          case msal.LogLevel.Info:
            console.log(message);
            return;
          case msal.LogLevel.Verbose:
            console.log(message);
            return;
          case msal.LogLevel.Warning:
            console.log(message);
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};
